import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Services/Hero";
import Strategy from "../components/Services/Strategy";
import Differentiation from "../components/Services/Differentiation";
import Services from "../components/Services/Services";

// eslint-disable-next-line
export const ServicesPageTemplate = ({
  heropitch,
  strategypitch,
  servicespitch,
  pageContext,
}) => {

  return (
    <>
      <div>
        <Hero content={heropitch} language={pageContext.language || "en"} />
        <Strategy content={strategypitch} />
        {/* <Differentiation content={differentiationpitch} /> */}
        <Services content={servicespitch} alternate={true}/>
      </div>
    </>
  );
};

ServicesPageTemplate.propTypes = {
  heropitch: PropTypes.object,
  strategypitch: PropTypes.object,
  servicespitch: PropTypes.object,
};

const ServicesPage = ({ data, pageContext, path }) => {
  const { frontmatter } = data.markdownRemark;

  const frontmatterFromLanguage = frontmatter[pageContext.language || "en"];

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <ServicesPageTemplate
        heropitch={frontmatterFromLanguage.heropitch}
        strategypitch={frontmatterFromLanguage.strategypitch}
        servicespitch={frontmatterFromLanguage.servicespitch}
        pageContext={pageContext}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(
      frontmatter: { en: { templateKey: { eq: "services-page" } } }
    ) {
      frontmatter {
        en {
          heropitch {
            title1
            title2
            description
          }
          strategypitch {
            title1
            title2
            description

          }
          servicespitch {
            title1
            title2
            services {
              title2
              description
              icon {
                publicURL
              }
            }
          }
        }
        it {
          heropitch {
            title1
            title2
            description
          }
          strategypitch {
            title1
            title2
            description
          }
          servicespitch {
            title1
            title2
            services {
              title2
              description
              icon {
                publicURL
              }
            }
          }
        }
        es {
          heropitch {
            title1
            title2
            description
          }
          strategypitch {
            title1
            title2
            description

          }
          servicespitch {
            title1
            title2
            services {
              title2
              description
              icon {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
